import { Component, OnInit, ViewChild } from "@angular/core";
import { GoogleMap, MapInfoWindow } from "@angular/google-maps";
import { ActivatedRoute, Router } from "@angular/router";
import { NGXLogger } from "ngx-logger";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import {
  ICourse,
  IGeoLocation,
  IHole,
  IHoleLocation
} from "../../models/course";
import { CourseService } from "../course.service";

@Component({
  selector: "app-course",
  styleUrls: ["./course.component.css"],
  templateUrl: "./course.component.html"
})
export class CourseComponent implements OnInit {
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap
  @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow
  public teeTypes: ITeeTypeData[] = [];
  public course: ICourse = {} as ICourse;
  public markers: IMarker[] = [];
  public currentHole: any = {};
  private id: any;
  private readonly google = "google";
  public mapOptions: google.maps.MapOptions = {
    mapTypeId: 'satellite',
    rotateControl: true,
    streetViewControl: false,
  };
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private courseService: CourseService,
    private toastr: ToastrService,
    private spinnerService: NgxSpinnerService,
    private logger: NGXLogger
  ) {
    // create teetypes
    this.teeTypes.push({ id: 1, name: "Black", selected: false });
    this.teeTypes.push({ id: 2, name: "Blue", selected: false });
    this.teeTypes.push({ id: 3, name: "Gold", selected: false });
    this.teeTypes.push({ id: 4, name: "Green", selected: false });
    this.teeTypes.push({ id: 5, name: "Red", selected: false });
    this.teeTypes.push({ id: 6, name: "White", selected: false });
    this.teeTypes.push({ id: 7, name: "Yellow", selected: false });
  }

  public ngOnInit() {
    this.route.params.subscribe(p => {
      this.id = p['id'];
      this.getCourse();
    });
  }

  public nextHole() {
    let holeIndex = this.course.holes.indexOf(this.currentHole);
    if (++holeIndex === this.course.holes.length) {
      holeIndex = 0;
    }
    this.selectHole(this.course.holes[holeIndex]);
  }

  public previousHole() {
    let holeIndex = this.course.holes.indexOf(this.currentHole);
    if (--holeIndex === -1) {
      holeIndex = this.course.holes.length - 1;
    }
    this.selectHole(this.course.holes[holeIndex]);
  }

  public selectHole(hole: IHole) {
    this.currentHole = hole;
    this.setMarkers();
    this.zoomToHole();
  }

  public isHoleValid(hole: IHole): boolean {
    this.logger.debug(hole.number);
    hole.holeLocations.forEach(location => {
      if (!this.isLocationValid(location)) {
        return false;
      }
    });
    return true;
  }

  public removeMarker(marker: IMarker) {
    const hole: IHole = this.currentHole as IHole;
    const index = hole.holeLocations.indexOf(
      marker.entityLocation as IHoleLocation
    );
    hole.holeLocations.splice(index, 1);
    this.setMarkers();
  }

  public mapClicked(event: google.maps.MapMouseEvent) {
    this.infoWindow.position = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng()
    }
    this.infoWindow.open();
  }

  public zoomToHole(): void {
    const tempBounds: any = new window[
      this.google
    ].maps.LatLngBounds();

    // expand to include hole locaitons
    this.currentHole.holeLocations.forEach(location => {
      if (this.isLocationValid(location)) {
        tempBounds.extend(this.getLatLng(location));
      }
    });

    // expand to include tee
    const tee = this.currentHole.holeTees[0];
    if (this.isLocationValid(tee)) {
      tempBounds.extend(this.getLatLng(tee));
    }
    if (!tempBounds.isEmpty()) {
      this.map.fitBounds(tempBounds);
    }
  }

  public zoomToCourse(): void {
    const tempBounds: any = new window[
      this.google
    ].maps.LatLngBounds();

    this.course.holes.forEach(hole => {
      // expand to include locations
      hole.holeLocations.forEach(location => {
        tempBounds.extend(this.getLatLng(location));
      });

      // expand to include tees
      hole.holeTees.forEach(location => {
        tempBounds.extend(this.getLatLng(location));
      });
    });

    this.map.fitBounds(tempBounds);
  }

  public save(): void {
    this.spinnerService.show();
    // console.log(this.course);
    this.courseService.save(this.course).subscribe(
      course => {
        this.setEditCourse(course);
        this.spinnerService.hide();
        this.toastr.success(this.course.name + " saved", "Course saved");
      },
      error => {
        this.spinnerService.hide();
        this.logger.error(error);
      }
    );
  }

  public zoomToTee(): void {
    const tempBounds: any = new window[
      this.google
    ].maps.LatLngBounds();

    // expand to inlcude current tee
    tempBounds.extend(this.getLatLng(this.currentHole.holeTees[0]));
    this.map.fitBounds(tempBounds);
  }

  public zoomToGreen(): void {
    const tempBounds: any = new window[
      this.google
    ].maps.LatLngBounds();
    this.currentHole.holeLocations.forEach(location => {
      if (
        location.holeLocationTypeId === "GreenCenter" ||
        location.holeLocationTypeId === "GreenBack" ||
        location.holeLocationTypeId === "GreenFront"
      ) {
        tempBounds.extend(this.getLatLng(location));
      }
    });
    this.map.fitBounds(tempBounds);
  }

  public markerDragEnd(marker: IMarker, $event: any) {
    // update the location of the marker's associated entity
    marker.entityLocation.latitude = $event.coords.lat;
    marker.entityLocation.longitude = $event.coords.lng;
  }

  public addGreenCenter() {
    // find the location associated with the GreenCenter
    const location = this.currentHole.holeLocations.find(
      h => h.holeLocationTypeId === "GreenCenter"
    );
    location.latitude = this.infoWindow.getPosition().lat();
    location.longitude = this.infoWindow.getPosition().lng();

    // refresh markers
    this.setMarkers();
    this.infoWindow.close();
  }

  public addTee() {
    // find the location associated with the first tee (TODO: this needs to change to support multiple tees)
    const location = this.currentHole.holeTees[0];
    location.latitude = this.infoWindow.getPosition().lat();
    location.longitude = this.infoWindow.getPosition().lng();

    // refresh markers
    this.setMarkers();
    this.infoWindow.close();
  }

  public addHazard() {
    // create a new hazard hole location
    const holeLocation: IHoleLocation = {
      holeId: this.currentHole.id,
      holeLocationTypeId: "Hazard",
      id: 0,
      latitude: this.infoWindow.getPosition().lat(),
      longitude: this.infoWindow.getPosition().lng()
    };
    this.currentHole.holeLocations.push(holeLocation);

    // refresh markers
    this.setMarkers();
    this.infoWindow.close();
  }

  public onTeeTypeChange(teeType) {
    // loop through holes
    this.course.holes.forEach(hole => {
      // find the holeTee associcated with the selected id
      const value = hole.holeTees.find(
        holeTee => holeTee.teeTypeId === teeType.name
      );

      // if the tee type is not selected, remove the holeTee
      if (!teeType.selected) {
        if (value !== undefined) {
          const index = hole.holeTees.indexOf(value);
          hole.holeTees.splice(index, 1);
        }
        // if the tee type is selected but we dont have a holeTee, add one
      } else if (value === undefined) {
        hole.holeTees.push({
          // holeId: hole.id,
          // id: -1,
          id: 0,
          latitude: this.course.latitude,
          length: 300,
          longitude: this.course.longitude,
          teeTypeId: teeType.name
        });
      }
    });
  }

  private getCourse() {
    // new course
    if (this.id === 0) {
      this.courseService.create().subscribe(
        course => {
          this.setEditCourse(course);
        },
        error => {
          this.logger.error(error);
        }
      );
    } else {
      // existing course
      this.courseService.getCourse(this.id).subscribe(
        course => {
          this.setEditCourse(course);
        },
        error => {
          this.logger.error(error);
        }
      );
    }
  }

  private clearMarkers(): void {
    this.markers = [];
  }

  private isLocationValid(location: IGeoLocation) {
    return (
      location != null && location.latitude !== 0 && location.longitude !== 0
    );
  }

  private setMarkers(): void {
    // clear markers
    this.clearMarkers();

    this.course.holes.forEach(hole => {
      const label: any = {
        color: hole === this.currentHole ? "black" : "white",
        fontWeight: "bold",
        text: hole.number.toString()
      };

      // expand to include hole locaitons
      hole.holeLocations.forEach(location => {
        // only add markers for valid locations
        if (this.isLocationValid(location)) {
          // process hazards and greens
          const iconUrl = {
            anchor: new window[this.google].maps.Point(24, 48),
            labelOrigin: new window[this.google].maps.Point(21, 17),
            url:
              hole === this.currentHole
                ? "assets/images/marker_flag.png"
                : "assets/images/marker_flag_disabled.png"
            // size: new google.maps.Size(32, 38),
            // size: new window[this.google].maps.Size(100, 100),
          };
          if (location.holeLocationTypeId === "Hazard") {
            iconUrl.url = "assets/images/marker_hazard.png";
            iconUrl.labelOrigin = new window[this.google].maps.Point(15, 12);
          } else if (location.holeLocationTypeId === "GreenCenter") {
            iconUrl.anchor = new window[this.google].maps.Point(31, 46);
          }

          // only add hazards for current hole
          if (
            location.holeLocationTypeId !== "Hazard" ||
            (location.holeLocationTypeId === "Hazard" &&
              this.currentHole === hole)
          ) {
            const marker: IMarker = {
              options: {
                draggable: hole === this.currentHole,
                icon: iconUrl
              },
              entityLocation: location,
              label,
              position: {
                lat: location.latitude,
                lng: location.longitude,
              },
              title: location.holeLocationTypeId
            };
            this.markers.push(marker);
          }
        }
      });

      // expand to include tee
      const tee = hole.holeTees[0];

      if (this.isLocationValid(tee)) {
        // create tee marker
        const teeMarker: IMarker = {
          options: {
            draggable: hole === this.currentHole,
            icon: hole === this.currentHole
            ? "assets/images/marker_tee.png"
            : "assets/images/marker_tee_disabled.png",
          },
          entityLocation: tee,
          label,
          position:
          {
            lat: tee.latitude,
            lng: tee.longitude,
          },
          title: tee.teeTypeId
        };
        this.markers.push(teeMarker);
      }
    });
  }

  private setEditCourse(course: ICourse) {
    if (course) {
      this.course = course;
      // since all holes should have the same tee types, only inspect the first hole
      const hole = this.course.holes[0];

      // select tee types based on existing holes
      this.teeTypes.forEach(teeType => {
        // find the hole tee for this hole
        const value = hole.holeTees.find(
          holeTee => holeTee.teeTypeId === teeType.name
        );
        if (value !== undefined) {
          teeType.selected = true;
        }
      });

      this.currentHole = this.course.holes[0];
      this.setMarkers();
      this.zoomToHole();
    } else {
      // do nothing
    }
  }

  private getLatLng(location: IGeoLocation) {
    return new window[this.google].maps.LatLng(
      location.latitude,
      location.longitude
    );
  }
}

interface IInfoWindow {
  latitude: number;
  longitude: number;
}

interface ITeeTypeData {
  id: number;
  name: string;
  selected: boolean;
}

// just an interface for type safety.
interface IMarker {
  options: google.maps.MarkerOptions;
  position: google.maps.LatLngLiteral;
  label?: any;
  lableClass?: any;
  title?: string;
  entityLocation: IGeoLocation;
}
