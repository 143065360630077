import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NGXLogger } from "ngx-logger";
import { NgxSpinnerService } from "ngx-spinner";
import { CourseService } from "../../courses/course.service";
import { ICourse } from "../../models/course";
import { IScorecard, IScorecardPlayer } from "../../models/scorecard";
import { ScorecardService } from "../scorecard.service";

@Component({
  selector: "app-scorecard",
  styleUrls: ["./scorecard.component.css"],
  templateUrl: "./scorecard.component.html"
})
export class ScorecardComponent implements OnInit {
  public scorecard: IScorecard;
  public course: ICourse;

  public outMensPar: number;
  public inMensPar: number;
  public totalMensPar: number;

  public outWomensPar: number;
  public inWomensPar: number;
  public totalWomensPar: number;
  private id: any;
  constructor(
    private spinnerService: NgxSpinnerService,
    private route: ActivatedRoute,
    private router: Router,
    private scorecardService: ScorecardService,
    private courseService: CourseService,
    private logger: NGXLogger
  ) {}

  public ngOnInit() {
    this.route.params.subscribe(p => {
      this.getScorecard(p['id']);
    });
  }

  private getScorecard(id: number) {
    this.spinnerService.show();

    // existing course
    this.scorecardService.get(id).subscribe(
      scorecard => {
        this.setScorecard(scorecard);
      },
      error => {
        this.logger.error(error);
      },
      () => {
        this.spinnerService.hide();
      }
    );
  }

  private setScorecard(scorecard: IScorecard) {
    this.scorecard = scorecard;
    this.courseService.getCourse(this.scorecard.courseId).subscribe(
      course => {
        this.build(course);
        // console.log(scorecard);
        // console.log(course);
      },
      error => {
        this.logger.error(error);
      }
    );
  }

  private buildPlayer(course: ICourse, scorecardPlayer: IScorecardPlayer) {
    const player: any = scorecardPlayer;
    player.front = [];
    player.back = [];

    let frontFairwaysPlayed = 0;
    let totalFairwaysPlayed = 0;
    let frontFairwaysHit = 0;
    let totalFairwaysHit = 0;
    let frontGirPlayed = 0;
    let totalGirPlayed = 0;
    let frontGirHit = 0;
    let totalGirHit = 0;
    let frontParPlayed = 0;
    let totalParPlayed = 0;

    let isFront = true;

    // add new properties for aggregation
    player.totalPenaltyStrokes = 0;
    player.totalSandShots = 0;
    player.totalPutts = 0;

    player.totalStrokes = 0;
    player.totalScore = "";

    course.holes.forEach(hole => {
      // find the player hole associated with the hole id
      const playerHole: any = scorecardPlayer.scorecardPlayerHoles.find(
        x => x.holeId === hole.id
      );
      const tee = hole.holeTees[0];

      if (hole.number === 10) {
        isFront = false;
        frontFairwaysPlayed = totalFairwaysPlayed;
        frontFairwaysHit = totalFairwaysHit;
        frontGirPlayed = totalGirPlayed;
        frontGirHit = totalGirHit;
        player.frontStrokes = player.totalStrokes;
        player.frontSandShots = player.totalSandShots;
        player.frontPenaltyStrokes = player.totalPenaltyStrokes;
        player.frontPutts = player.totalPutts;
        frontParPlayed = totalParPlayed;
        player.frontScore = this.getParString(
          totalParPlayed,
          player.totalStrokes
        );
      }

      if (playerHole.strokeCount != null) {
        // fir
        if (playerHole.fairwayInRegulation != null) {
          totalFairwaysPlayed++;
          if (playerHole.fairwayInRegulation) {
            totalFairwaysHit++;
            playerHole.fairwayInRegulationLabel = "✓";
          } else {
            playerHole.fairwayInRegulationLabel = "✗";
          }
        }

        // gir
        if (playerHole.puttCount != null) {
          totalGirPlayed++;
          if (
            playerHole.strokeCount - playerHole.puttCount <=
            hole.mensPar - 2
          ) {
            totalGirHit++;
            playerHole.greenInRegulationLabel = "✓";
          } else {
            playerHole.greenInRegulationLabel = "✗";
          }
        }

        totalParPlayed +=
          scorecardPlayer.gender === "Male" ? hole.mensPar : hole.womensPar;
        player.totalStrokes += playerHole.strokeCount || 0;
        player.totalPutts += playerHole.puttCount || 0;
        player.totalSandShots += playerHole.sandShotCount || 0;
        player.totalPenaltyStrokes += playerHole.penaltyStrokeCount || 0;
        playerHole.runningScore = this.getParString(
          totalParPlayed,
          player.totalStrokes
        );
      }
      // add player hole
      if (isFront) {
        player.front.push(playerHole);
      } else {
        player.back.push(playerHole);
      }
    });

    player.frontFir = this.getPercentString(
      frontFairwaysHit,
      frontFairwaysPlayed
    );
    player.backFir = this.getPercentString(
      totalFairwaysHit - frontFairwaysHit,
      totalFairwaysPlayed - frontFairwaysPlayed
    );
    player.totalFir = this.getPercentString(
      totalFairwaysHit,
      totalFairwaysPlayed
    );

    player.frontGir = this.getPercentString(frontGirHit, frontGirPlayed);
    player.backGir = this.getPercentString(
      totalGirHit - frontGirHit,
      totalGirPlayed - frontGirPlayed
    );
    player.totalGir = this.getPercentString(totalGirHit, totalGirPlayed);

    player.backSandShots = player.totalSandShots - player.frontSandShots;
    player.backPenaltyStrokes =
      player.totalPenaltyStrokes - player.frontPenaltyStrokes;
    player.backStrokes = player.totalStrokes - player.frontStrokes;
    player.backPutts = player.totalPutts - player.frontPutts;
    player.backScore = this.getParString(
      totalParPlayed - frontParPlayed,
      player.backStrokes
    );
    player.totalScore = this.getParString(totalParPlayed, player.totalStrokes);
  }

  private build(course: ICourse) {
    this.outMensPar = this.outWomensPar = this.inMensPar = this.inWomensPar = this.totalMensPar = this.totalWomensPar = 0;
    course.holes.forEach(hole => {
      if (hole.number < 10) {
        this.outMensPar += hole.mensPar;
        this.outWomensPar += hole.womensPar;
      } else {
        this.inMensPar += hole.mensPar;
        this.inWomensPar += hole.womensPar;
      }
    });

    this.totalMensPar += this.inMensPar + this.outMensPar;
    this.totalWomensPar += this.inWomensPar + this.outWomensPar;

    this.scorecard.scorecardPlayers.forEach(player => {
      this.buildPlayer(course, player);
    });
    this.course = course;
  }
  private getParString(par: number, score: number): string {
    const dif = score - par;
    return par === score ? "E" : dif > 0 ? `+${dif}` : `${dif}`;
  }

  private getPercentString(numerator: number, denominator: number): string {
    const value = Math.ceil(
      denominator !== 0 ? (numerator / denominator) * 100 : 0
    );
    return `${value}%`;
  }
}
