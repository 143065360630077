import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule } from "@angular/forms";
import { GoogleMapsModule } from '@angular/google-maps'
import { CourseListComponent } from "./course-list/course-list.component";
import { CourseService } from "./course.service";
import { CourseComponent } from "./course/course.component";
import { CoursesRoutingModule } from "./courses-routing.module";
import { CoursesComponent } from "./courses.component";

@NgModule({
  declarations: [CoursesComponent, CourseListComponent, CourseComponent],
  imports: [
    CommonModule,
    FormsModule,
    CoursesRoutingModule,
    BrowserModule,
    GoogleMapsModule
  ],
  providers: [CourseService]
})
export class CoursesModule {}
