import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { HomeComponent } from "./home/home.component";

const routes: Routes = [
  { path: "", pathMatch: "full", component: HomeComponent },
  { path: "**", redirectTo: "" }
];
@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(routes, {})]
})
export class AppRoutingModule {}
