import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-scorecards",
  styleUrls: ["./scorecards.component.css"],
  templateUrl: "./scorecards.component.html"
})
export class ScorecardsComponent implements OnInit {
  constructor() {
    // do nothing
  }

  public ngOnInit() {
    // do nothing
  }
}
