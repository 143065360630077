import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AppSettings } from "../app-settings";
import { ICourse } from "../models/course";

@Injectable()
export class CourseService {
  constructor(private http: HttpClient) {}
  public getCourses(): Observable<ICourse[]> {
    const url = `${AppSettings.getApiRoot()}courses/search?courseName=e`;
    return this.http.get<ICourse[]>(url);
  }

  public getCourse(id: number): Observable<ICourse> {
    const url = `${AppSettings.getApiRoot()}courses/${id}`;
    return this.http.get<ICourse>(url);
  }

  public delete(id: number): Observable<ICourse> {
    const url = `${AppSettings.getApiRoot()}courses/${id}`;
    return this.http.delete<ICourse>(url);
  }

  public create(): Observable<ICourse> {
    const url = `${AppSettings.getApiRoot()}courses/create`;
    return this.http.get<ICourse>(url);
  }

  public save(course: ICourse): Observable<ICourse> {
    if (course.id === 0) {
      const url = `${AppSettings.getApiRoot()}courses`;
      return this.http.post<ICourse>(url, course);
    } else {
      const url = `${AppSettings.getApiRoot()}courses/${course.id}`;
      return this.http.put<ICourse>(url, course);
    }
  }
}
