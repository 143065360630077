import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-courses",
  styleUrls: ["./courses.component.css"],
  templateUrl: "./courses.component.html"
})
export class CoursesComponent implements OnInit {
  constructor() {
    // do nothing
  }

  public ngOnInit() {
    // do nothing
  }
}
