import { Component, OnInit } from "@angular/core";
// import { AppInsightsService } from "@markpieszak/ng-application-insights";
import {
  SocialAuthService,
  GoogleLoginProvider,
  SocialUser
} from "@abacritt/angularx-social-login";
import { NGXLogger } from "ngx-logger";

@Component({
  selector: "app-nav-menu",
  styleUrls: ["./nav-menu.component.css"],
  templateUrl: "./nav-menu.component.html"
})
export class NavMenuComponent implements OnInit {
  public user: SocialUser;

  constructor(
    private authService: SocialAuthService,
    // private appInsightsService: AppInsightsService,
    private logger: NGXLogger
  ) {}

  public ngOnInit() {
    this.authService.authState.subscribe(user => {
      this.user = user;
      if (user === null) {
        this.logger.info("clearing user context");
        // this.appInsightsService.clearAuthenticatedUserContext();
        // localStorage.removeItem('authToken');
      } else {
        this.logger.info("setting user context: " + user.email);
        // this.appInsightsService.setAuthenticatedUserContext(user.email);
        localStorage.setItem("authToken", user.idToken);
      }
    });
  }

  public signIn(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then(user => {
      // this.user = user;
      // this.appInsightsService.setAuthenticatedUserContext(user.id);
      // localStorage.setItem('authToken', user.idToken);
    });
  }

  public signOut(): void {
    this.authService.signOut();
    localStorage.removeItem("authToken");
    // localStorage.removeItem('authToken');
    // this.appInsightsService.clearAuthenticatedUserContext();
  }

  public isOnline(): boolean {
    return this.user != null;
    // return localStorage.getItem('authToken') !== null && this.user != null;
  }
}
