import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-home",
  styleUrls: ["./home.component.css"],
  templateUrl: "./home.component.html"
})
export class HomeComponent implements OnInit {
  constructor() {
    // do nothing
  }
  public ngOnInit() {
    // do nothing
  }

  public getToken() {
    // do nothing
  }
}
