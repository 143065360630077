import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AppSettings } from "../app-settings";
import { IChart } from "../models/chart";

@Injectable()
export class ReportService {
  constructor(private http: HttpClient) {}

  public getReport(id: number): Observable<IChart> {
    const url = `${AppSettings.getApiRoot()}reports?reportTypeId=${id}`;
    return this.http.get<IChart>(url);
  }

  public get(): Observable<IChart[]> {
    const url = `${AppSettings.getApiRoot()}reports`;
    return this.http.get<IChart[]>(url);
  }
}
