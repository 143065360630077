import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { NgChartsModule } from "ng2-charts";
import { AnalyticsRoutingModule } from "./analytics-routing.module";
import { AnalyticsComponent } from "./analytics.component";
import { ReportService } from "./report.service";

@NgModule({
  declarations: [AnalyticsComponent],
  imports: [CommonModule, AnalyticsRoutingModule, NgChartsModule],
  providers: [ReportService]
})
export class AnalyticsModule {}
