import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
// import {
//   AppInsightsService,
//   ApplicationInsightsModule
// } from "@markpieszak/ng-application-insights";
import {
  SocialAuthServiceConfig,
  GoogleLoginProvider,
  SocialLoginModule
} from "@abacritt/angularx-social-login";
import { LoggerModule, NgxLoggerLevel } from "ngx-logger";
import { NgxSpinnerModule } from "ngx-spinner";
import { ToastrModule } from "ngx-toastr";
import { AnalyticsModule } from "./analytics/analytics.module";
import { AppRoutingModule } from "./app-routing.moudle";
import { AppSettings } from "./app-settings";
import { AppComponent } from "./app.component";
import { CoursesModule } from "./courses/courses.module";
import { HomeComponent } from "./home/home.component";
import { NavMenuComponent } from "./nav-menu/nav-menu.component";
import { ScorecardsModule } from "./scorecards/scorecards.module";
import { TokenInterceptorService } from "./token-interceptor.service";

@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent, NavMenuComponent, HomeComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot({
      // positionClass: 'toast-bottom-right'
    }),
    NgxSpinnerModule,
    FormsModule,
    HttpClientModule,
    CoursesModule,
    ScorecardsModule,
    AnalyticsModule,
    AppRoutingModule,
    RouterModule,
    LoggerModule.forRoot({ level: NgxLoggerLevel.DEBUG }),
    SocialLoginModule,
    // ApplicationInsightsModule.forRoot({
    //   enableCorsCorrelation: true, // enable cors since the api is cross origin
    //   instrumentationKey: AppSettings.appInsightsKey
    // })
  ],
  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              AppSettings.googleClientId
            ),
          }
        ],
      } as SocialAuthServiceConfig,
    },
    {
      multi: true,
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService
    },
    // AppInsightsService
  ]
})
export class AppModule {}
