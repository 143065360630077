import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { SocialAuthService } from "@abacritt/angularx-social-login";
import { NGXLogger } from "ngx-logger";

// !!!!https://ryanchenkie.com/angular-authentication-using-route-guards

@Injectable({
  providedIn: "root"
})
export class AuthGuardService {
  private loggedIn: boolean;

  constructor(
    public router: Router,
    private authService: SocialAuthService,
    private logger: NGXLogger
  ) {}

  public canActivate(): boolean {
    if (localStorage.getItem("authToken") === null) {
      this.logger.warn("route guard prevented navigation");
      this.router.navigate([""]);
      return false;
    }
    return true;
  }
}
