import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuardService } from "../auth-guard.service";
import { AnalyticsComponent } from "./analytics.component";

const routes: Routes = [
  {
    canActivate: [AuthGuardService],
    component: AnalyticsComponent,
    path: "analytics"
  }
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forChild(routes)]
})
export class AnalyticsRoutingModule {}
