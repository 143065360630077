import { Component, OnInit } from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { IScorecardSummary } from "../../models/scorecard";
import { ScorecardService } from "../scorecard.service";

@Component({
  selector: "app-scorecard-list",
  styleUrls: ["./scorecard-list.component.css"],
  templateUrl: "./scorecard-list.component.html"
})
export class ScorecardListComponent implements OnInit {
  public scorecards: IScorecardSummary[] = [];
  public selectedScorecard: IScorecardSummary;

  constructor(
    private spinnerService: NgxSpinnerService,
    private toastr: ToastrService,
    private scorecardService: ScorecardService,
    private logger: NGXLogger
  ) {}

  public ngOnInit() {
    this.getScorecards();
  }

  public getScorecards() {
    this.spinnerService.show();
    this.scorecards = [];
    const retVal = this.scorecardService.getScorecards().subscribe(
      scorecards => {
        this.scorecards = scorecards;
      },
      error => {
        this.logger.error(error);
      },
      () => {
        this.spinnerService.hide();
      }
    );
  }

  public select(scorecard: IScorecardSummary): void {
    this.selectedScorecard = scorecard;
  }

  public delete(scorecard: IScorecardSummary): void {
    this.spinnerService.show();
    const retVal = this.scorecardService.delete(scorecard.id).subscribe(
      deletedScorecard => {
        this.getScorecards();
        this.toastr.success(
          "Scorecard successfully deleted",
          "Scorecard deleted"
        );
      },
      error => {
        this.logger.error(error);
      },
      () => {
        this.spinnerService.hide();
      }
    );
  }
}
