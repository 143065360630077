import { Component, OnInit } from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { ICourse } from "../../models/course";
import { CourseService } from "../course.service";

@Component({
  selector: "app-course-list",
  styleUrls: ["./course-list.component.css"],
  templateUrl: "./course-list.component.html"
})
export class CourseListComponent implements OnInit {
  public courses: ICourse[] = [];
  public selectedCourse: ICourse;
  constructor(
    private spinnerService: NgxSpinnerService,
    private toastr: ToastrService,
    private courseService: CourseService,
    private logger: NGXLogger
  ) {}
  public ngOnInit() {
    this.getCourses();
  }

  public getCourses() {
    this.logger.debug("getting courses");
    this.spinnerService.show();
    this.courses = [];
    const retVal = this.courseService.getCourses().subscribe(
      courses => {
        this.courses = courses;
      },
      error => {
        this.logger.error(error);
      },
      () => {
        this.spinnerService.hide();
      }
    );
  }

  public select(course: ICourse): void {
    this.selectedCourse = course;
  }

  public delete(course: ICourse): void {
    this.spinnerService.show();
    const retVal = this.courseService.delete(course.id).subscribe(
      deletedCourse => {
        this.getCourses();
        this.toastr.success(
          deletedCourse.name + " successfully deleted",
          "Course deleted"
        );
      },
      error => {
        this.logger.error(error);
      },
      () => {
        this.spinnerService.hide();
      }
    );
  }
}
