import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuardService } from "../auth-guard.service";
import { ScorecardListComponent } from "./scorecard-list/scorecard-list.component";
import { ScorecardComponent } from "./scorecard/scorecard.component";
import { ScorecardsComponent } from "./scorecards.component";

const routes: Routes = [
  {
    canActivate: [AuthGuardService],
    children: [
      { path: "", component: ScorecardListComponent },
      { path: ":id", component: ScorecardComponent }
    ],
    component: ScorecardsComponent,
    path: "scorecards"
  }
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forChild(routes)]
})
export class ScorecardsRoutingModule {}
