import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AppSettings } from "../app-settings";
import { IScorecard, IScorecardSummary } from "../models/scorecard";

@Injectable()
export class ScorecardService {
  constructor(private http: HttpClient) {}
  public getScorecards(): Observable<IScorecardSummary[]> {
    const url = `${AppSettings.getApiRoot()}scorecards`;
    return this.http.get<IScorecardSummary[]>(url);
  }
  public delete(id: number): Observable<IScorecardSummary> {
    const url = `${AppSettings.getApiRoot()}scorecards/${id}`;
    return this.http.delete<IScorecardSummary>(url);
  }
  public get(id: number): Observable<IScorecard> {
    const url = `${AppSettings.getApiRoot()}scorecards/${id}`;
    return this.http.get<IScorecard>(url);
  }
}
