import { Component, OnInit } from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { NgxSpinnerService } from "ngx-spinner";
import { IChart } from "../models/chart";
import { ReportService } from "./report.service";

@Component({
  selector: "app-analytics",
  styleUrls: ["./analytics.component.css"],
  templateUrl: "./analytics.component.html"
})
export class AnalyticsComponent implements OnInit {
  public charts: any[] = [];
  constructor(
    private reportService: ReportService,
    private spinnerService: NgxSpinnerService,
    private logger: NGXLogger
  ) {}

  public getReports() {
    this.spinnerService.show();
    const retVal = this.reportService.get().subscribe(
      charts => {
        this.buildCharts(charts);
      },
      error => {
        this.logger.error(error);
      },
      () => {
        this.spinnerService.hide();
      }
    );
  }

  public ngOnInit() {
    this.getReports();
  }

  private buildCharts(charts: IChart[]) {
    charts.forEach(chart => {
      const chartData: any = {
        barChartData: [],
        barChartLabels: [],
        barChartLegend: true,
        barChartOptions: {
          responsive: true,
          scaleShowVerticalLines: false
        },
        barChartType: "bar",
        title: chart.title
      };

      chart.series.forEach(series => {
        const barChartData = {
          data: [],
          label: ""
        };

        chartData.barChartType = series.seriesType === "Pie" ? "pie" : "bar";

        series.dataPoints.forEach(dataPoint => {
          barChartData.data.push(dataPoint.value);
          // barChartData.label = dataPoint.label;
          chartData.barChartLabels.push(dataPoint.label);
        });
        chartData.barChartData.push(barChartData);
      });
      this.charts.push(chartData);
    });
  }
}
