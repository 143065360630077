import { environment } from "../environments/environment";

export class AppSettings {
  public static readonly mapApiKey = "AIzaSyDidE4E_ydjjIH3eSKlaVtzqxuv1SLaesM";
  public static readonly googleClientId =
    "5156881095-v2a7um5h4l11g7vedvcqitg3098nqvds.apps.googleusercontent.com";
  public static readonly appInsightsKey =
    "fb3ee514-28e7-49b5-9d8a-4ea4e694d406";
  public static getApiRoot(): string {
    return environment.production
      ? AppSettings.productionApiRoot
      : AppSettings.devApiRoot;
  }
  private static readonly devApiRoot = "http://localhost:49712/";
  private static readonly productionApiRoot =
    "https://golf.platinumsoft.net/api/";
}
