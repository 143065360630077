import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuardService } from "../auth-guard.service";
import { CourseListComponent } from "./course-list/course-list.component";
import { CourseComponent } from "./course/course.component";
import { CoursesComponent } from "./courses.component";

const routes: Routes = [
  {
    canActivate: [AuthGuardService],
    children: [
      { path: "", component: CourseListComponent },
      { path: ":id", component: CourseComponent }
    ],
    component: CoursesComponent,
    path: "courses"
  }
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forChild(routes)]
})
export class CoursesRoutingModule {}
