import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { CourseService } from "../courses/course.service";
import { ScorecardListComponent } from "./scorecard-list/scorecard-list.component";
import { ScorecardService } from "./scorecard.service";
import { ScorecardComponent } from "./scorecard/scorecard.component";
import { ScorecardsRoutingModule } from "./scorecards-routing.module";
import { ScorecardsComponent } from "./scorecards.component";

@NgModule({
  declarations: [
    ScorecardComponent,
    ScorecardListComponent,
    ScorecardsComponent
  ],
  imports: [CommonModule, FormsModule, ScorecardsRoutingModule, BrowserModule],
  providers: [ScorecardService, CourseService]
})
export class ScorecardsModule {}
